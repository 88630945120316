export var pageAnim = {
    initial:{
        opacity:0,
        y:-10,
    },
    animate:{
        opacity:1,
        y:0,
        transition:{duration:0.25}
    }
}